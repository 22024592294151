import Amplify, { Auth } from "aws-amplify";

import { UserModel } from "../models/user.model";

const REACT_APP_USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;

export async function getCurrentUser(): Promise<UserModel | null> {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const userSession = cognitoUser.getSignInUserSession();

    const user: UserModel = {
      accessToken: userSession.accessToken.jwtToken,
      idToken: userSession.idToken.jwtToken,
      username: cognitoUser.username,
      email: cognitoUser.attributes.email,
      sub: cognitoUser.attributes.sub,
      groups: userSession.accessToken.payload["cognito:groups"] || [],
    };

    return user;
  } catch (error) {
    // nothing to do
  }

  return null;
}

export async function getCurrentUserToken(): Promise<string> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const userSession = cognitoUser.getSignInUserSession();

  return userSession.idToken.jwtToken;
}

export function configure(): void {
  Amplify.configure({
    Auth: {
      region: REACT_APP_AWS_REGION,
      userPoolId: REACT_APP_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_USER_POOL_CLIENT_ID,
      authenticationFlowType: "USER_SRP_AUTH",
    },
  });
}
