type TooltipType =
  | "errors_failed"
  | "errors_none"
  | "complete"
  | "both_error"
  | "error_and_none"
  | "loading"
  | "complete_and_errors_pending"
  | "complete_pending"
  | "errors_pending"
  | "extracted_products_failed"
  | "extracted_products_pending";

export function getDisabledReportTooltip(type: TooltipType): string {
  switch (type) {
    case "complete":
      return "Error during complete report generation";
    case "errors_failed":
      return "Error during errors report generation";
    case "errors_none":
      return "No errors during import job";
    case "error_and_none":
      return "Reports are not available or error occurred during generation";
    case "both_error":
      return "Errors occurred during reports generation";
    case "loading":
      return "Downloading";
    case "complete_and_errors_pending":
      return "Reports are not available yet";
    case "complete_pending":
      return "Complete report is not available yet";
    case "errors_pending":
      return "Errors report is not available yet";
    case "extracted_products_failed":
      return "Error occurred during extracted products file generation";
    case "extracted_products_pending":
      return "Extracted products file is not available yet";
    default:
      return "";
  }
}
