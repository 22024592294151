import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ComponentProps {
  open: boolean;
  okButtonText?: string;
  okButtonHandler?: () => void;
  cancelButtonText?: string;
  cancelButtonHandler?: () => void;
  title?: string;
  text?: string;
  childContent?: any;
  okButtonDisabled?: boolean;
}

export default function Component({
  open,
  okButtonText = "Ok",
  okButtonHandler,
  cancelButtonText = "Cancel",
  cancelButtonHandler,
  title = "Title",
  text = "Text",
  childContent,
  okButtonDisabled = false,
}: ComponentProps) {
  return (
    <Dialog
      open={open || false}
      onClose={() => {
        if (cancelButtonHandler) {
          cancelButtonHandler();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        {childContent}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (cancelButtonHandler) {
              cancelButtonHandler();
            }
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={() => {
            if (okButtonHandler) {
              okButtonHandler();
            }
          }}
          color="error"
          autoFocus
          disabled={okButtonDisabled}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
