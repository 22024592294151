import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import { EcmDealerModel } from "import-automation-tool-backend/src/models/ecmDealer.model";

export default function Component({
  open,
  okButtonText = "Create",
  okButtonHandler,
  cancelButtonText = "Cancel",
  cancelButtonHandler,
  title = "Title",
  dealers,
  dealersLoading,
}: InferProps<typeof Component.propTypes>) {
  const [projectName, setProjectName] = useState<string>("");
  const [projectDealer, setProjectDealer] = useState<EcmDealerModel | null>(
    null,
  );

  const clearData = () => {
    setProjectName("");
    setProjectDealer(null);
  };

  return (
    <Dialog
      open={open || false}
      onClose={() => {
        cancelButtonHandler();
        clearData();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            maxWidth: "490px",
            padding: "5px",
          }}
        >
          <Grid container rowSpacing={3} alignItems="center">
            <Grid item xs={12} sm={4}>
              Project Name
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                variant="outlined"
                fullWidth={true}
                onChange={event => setProjectName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              ECM Dealer
            </Grid>
            <Grid item xs={12} sm={8}>
              <Autocomplete
                options={dealers || []}
                loading={dealersLoading || false}
                disableClearable={true}
                value={projectDealer}
                fullWidth={true}
                getOptionLabel={value => `${value.id} ${value.name}`}
                onChange={(_event, value) => setProjectDealer(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {dealersLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            cancelButtonHandler();
            clearData();
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          disabled={!projectName || !projectDealer}
          onClick={() => {
            okButtonHandler({ name: projectName, dealerId: projectDealer?.id });
            clearData();
          }}
          autoFocus
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Component.propTypes = {
  open: PropTypes.bool,
  okButtonText: PropTypes.string,
  okButtonHandler: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  cancelButtonHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  dealers: PropTypes.array,
  dealersLoading: PropTypes.bool,
  dealerInputLabelRender: PropTypes.func,
};
