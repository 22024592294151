import React from "react";
import ReactDOM from "react-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "./index.css";
import App from "./views/App/App";
import reportWebVitals from "./reportWebVitals";
import { configure as amplifyConfigure } from "./services/auth.service";

if (process.env.NODE_ENV === "development") {
  // 🛠️ Uncomment if you want to intercept HTTP requests with mock responses
  // require("./mocks/browser");
}

amplifyConfigure();

ReactDOM.render(
  <React.StrictMode>
    <Authenticator loginMechanisms={["email"]}>
      {({ signOut, user }) => (
        <App
          signOut={() => {
            if (signOut) {
              signOut();
            }
          }}
          user={user}
        />
      )}
    </Authenticator>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
