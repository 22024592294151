export class ScrapingError extends Error {
  statusPageUrl: string;

  constructor(message: string, statusPageUrl: string) {
    super(message); // breaks proto chain

    Object.setPrototypeOf(this, new.target.prototype);

    this.statusPageUrl = statusPageUrl;
  }
}
