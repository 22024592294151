import PropTypes, { InferProps } from "prop-types";

import "./App.css";
import ButtonAppBar from "../../components/AppBar";

import MainView from "../Main";

export default function App({
  user,
  signOut,
}: InferProps<typeof App.propTypes>) {
  return (
    <div className="app">
      <ButtonAppBar logoutFunction={signOut} />
      <MainView user={user} />
    </div>
  );
}

App.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
};
