import * as React from "react";
import { Alert as BaseAlert, Snackbar } from "@mui/material";

interface ComponentProps {
  children: React.ReactNode;
  isOpen: boolean;
  removeAlert: () => void;
  type?: ALERT_STATUS;
}

export enum ALERT_STATUS {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface AlertContent {
  type: ALERT_STATUS;
  message: string;
  linkUrl?: string;
}

export function Alert({
  isOpen,
  type = ALERT_STATUS.SUCCESS,
  children,
  removeAlert,
}: ComponentProps) {
  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    removeAlert();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <BaseAlert onClose={handleClose} severity={type}>
        {children}
      </BaseAlert>
    </Snackbar>
  );
}
