import { ReactNode } from "react";
import { Box, Button, Grid, Tooltip } from "@mui/material";

interface ComponentProps {
  buttons: {
    name: string;
    tooltip?: string;
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    disabled?: boolean;
    handler?: () => void;
    children?: ReactNode;
  }[];
}

export default function Component({ buttons }: ComponentProps) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid container spacing={1}>
        {buttons?.map(item => {
          return (
            <Grid
              item
              key={item.name}
              xs={6}
              sm={3}
              md={2}
              lg={1}
              sx={{ "> div": { height: "100%" } }}
            >
              {item?.children ? (
                item.children
              ) : (
                <Tooltip title={item.tooltip || ""} arrow>
                  <span>
                    <Button
                      fullWidth={true}
                      style={{
                        height: "100%",
                        maxWidth: "200px",
                      }}
                      size="small"
                      variant="outlined"
                      color={item.color || "primary"}
                      disabled={item.disabled}
                      onClick={() => {
                        if (item.handler) {
                          item.handler();
                        } else {
                          console.log(`Button [${item.name}] clicked`);
                        }
                      }}
                    >
                      {item.name}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
