import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";

interface OptionContext {
  onClick: () => void;
  label: string;
  disabled: boolean;
  disabledTooltip?: string;
}

type RegisterOptionArgs = OptionContext;
interface SplitButtonContextType {
  registerOption: (option: RegisterOptionArgs) => void;
  selectOption: (key: string) => void;
  selectedOptionKey?: string;
}

const SplitButtonContext = React.createContext<
  SplitButtonContextType | undefined
>(undefined);

interface SplitButtonProps {
  disabled?: boolean;
  disabledTooltip?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

interface SplitOptionProps {
  disabled?: boolean;
  disabledTooltip?: string;
  onClick: () => void;
  children: string;
}

export function SplitButton({
  disabled = false,
  disabledTooltip,
  icon,
  children,
  style,
}: SplitButtonProps) {
  const optionsRef = React.useRef<{
    [key: string]: Omit<OptionContext, "disabled">;
  }>({});
  const [open, setOpen] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState("");
  const [disableMainButton, setDisableMainButton] = React.useState(false);
  const selectedOption = optionsRef.current[selectedKey];
  const menuRef = React.useRef<HTMLDivElement>(null);

  const registerOption = React.useCallback(
    ({ disabled: isOptionDisabled, ...option }: RegisterOptionArgs) => {
      const keys = Object.keys(optionsRef.current);
      // if no choice is selected yet, we select the first one by default
      if (keys.length === 0) {
        setSelectedKey(option.label);
      }

      optionsRef.current = { ...optionsRef.current, [option.label]: option };

      if (option.label === selectedKey) {
        setDisableMainButton(isOptionDisabled);
      }
    },
    [selectedKey],
  );

  const selectOption = (key: string) => {
    setSelectedKey(key);
    setOpen(false);
  };

  const onClickAway = (event: Event) => {
    if (menuRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const tooltip =
    disabled && disabledTooltip
      ? disabledTooltip
      : selectedOption?.disabledTooltip;

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={menuRef}
        aria-label="split button"
        style={style}
      >
        <ButtonWithTooltip
          tooltip={tooltip}
          onClick={selectedOption?.onClick}
          startIcon={icon}
          disabled={disabled || disableMainButton}
        >
          {selectedOption?.label}
        </ButtonWithTooltip>
        <ButtonWithTooltip
          tooltip={tooltip}
          size="small"
          aria-controls="split-menu"
          aria-expanded={open}
          aria-haspopup="menu"
          disabled={disabled}
          onClick={() => setOpen(prevOpen => !prevOpen)}
        >
          <ArrowDropDownIcon />
        </ButtonWithTooltip>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={menuRef.current}
        role={undefined}
        keepMounted={true}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList id="split-menu" autoFocusItem>
                  <SplitButtonContext.Provider
                    value={{
                      registerOption,
                      selectOption,
                      selectedOptionKey: selectedKey,
                    }}
                  >
                    {children}
                  </SplitButtonContext.Provider>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export function SplitOption({
  onClick,
  children,
  disabled = false,
  disabledTooltip,
}: SplitOptionProps) {
  const context = React.useContext(SplitButtonContext);
  // we save the initial label to reference the option in SplitButton
  const keyRef = React.useRef(children);

  if (!context) {
    throw new Error("SplitOption should be used as a child of SplitButton");
  }

  const { registerOption, selectOption, selectedOptionKey } = context;
  const isSelected = selectedOptionKey === keyRef.current;

  React.useEffect(
    function registerOptionEffect() {
      registerOption({ onClick, label: children, disabled, disabledTooltip });
    },
    [registerOption, onClick, children, disabled, disabledTooltip],
  );

  const Item = (
    <MenuItem
      selected={isSelected}
      disabled={disabled}
      aria-current={isSelected || undefined}
      onClick={() => selectOption(keyRef.current)}
    >
      {children}
    </MenuItem>
  );

  if (disabled && disabledTooltip) {
    return (
      <Tooltip title={disabledTooltip}>
        <span>{Item}</span>
      </Tooltip>
    );
  }

  return Item;
}

interface ButtonWithTooltipProps extends ButtonProps {
  tooltip?: string;
}

// @see: https://stackoverflow.com/a/63276424/2683681
function ButtonWithTooltip({
  tooltip,
  disabled,
  onClick,
  ...otherProps
}: ButtonWithTooltipProps) {
  const adjustedButtonProps: ButtonProps<
    "button",
    { component?: React.ElementType }
  > = {
    disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };

  const ButtonComp = (
    <Button
      {...otherProps}
      {...adjustedButtonProps}
      sx={{ "&.Mui-disabled": { pointerEvents: "auto" } }}
    />
  );

  if (disabled && tooltip) {
    return <Tooltip title={tooltip}>{ButtonComp}</Tooltip>;
  }

  return ButtonComp;
}
