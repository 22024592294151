import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";

interface ComponentProps {
  open: boolean;
  title: string;
  text: string;
}

export default function Component({ open, title, text }: ComponentProps) {
  return (
    <Dialog
      open={open || false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
          <LinearProgress
            className="progress"
            style={{ borderRadius: "5px", height: "10px" }}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
