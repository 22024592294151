import * as React from "react";

import ApiService from "../services/api.service";

// TODO: find a way to share with backend
// for now it doesn't work to import from backend
// since this is an enum and requires transpilation from webpack
// but CRA webpack config only transpiles files inside src/ folder
export enum DownloadReportType {
  COMPLETE = "complete",
  ERRORS = "errors",
}

export enum DownloadReportStatus {
  IDLE = "idle",
  LOADING = "loading",
  ERROR = "error",
}

interface Args {
  ecmEnv: string;
  projectId: string;
  accountId: string;
  actionId: string;
}

interface State {
  status: DownloadReportStatus;
  errorMessage?: string;
  url?: DownloadReportReturnInnerType["url"];
}

interface Return {
  downloadReport: (types: DownloadReportType[]) => Promise<void>;
  state: State;
  isLoading: boolean;
}

type DownloadReportReturnInnerType = Awaited<
  ReturnType<ApiService["downloadReport"]>
>;

type Action =
  | { type: "start_download" }
  | {
      type: "downloaded";
      payload: DownloadReportReturnInnerType;
    }
  | { type: "error_download"; payload: State["errorMessage"] };

function reducer(state: State, action: Action): State {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case "start_download":
      return {
        ...state,
        status: DownloadReportStatus.LOADING,
        url: undefined,
        errorMessage: undefined,
      };
    case "downloaded":
      return { ...state, ...action.payload, status: DownloadReportStatus.IDLE };
    case "error_download":
      return {
        ...state,
        status: DownloadReportStatus.ERROR,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unexpected action");
  }
}

const apiService = new ApiService();

export function useDownloadReport(args: Args): Return {
  const { ecmEnv, projectId, accountId, actionId } = args;
  const [state, dispatch] = React.useReducer(reducer, {
    status: DownloadReportStatus.IDLE,
  });
  const cancelRef = React.useRef<AbortController>();

  React.useEffect(
    function cancelRequestEffect() {
      cancelRef.current = new AbortController();

      return () => cancelRef.current?.abort();
    },
    [ecmEnv, projectId, accountId, actionId],
  );

  const downloadReport = React.useCallback(
    async (types: DownloadReportType[]) => {
      dispatch({ type: "start_download" });

      try {
        const { url } = await apiService.downloadReport({
          types,
          ecmEnv,
          projectId,
          accountId,
          actionId,
          cancelSignal: cancelRef.current?.signal,
        });

        dispatch({
          type: "downloaded",
          payload: { url },
        });

        if (url) {
          window.open(url, "_self");
        }
      } catch (error) {
        const message =
          error instanceof Error ? error.message : "Unexpected error";
        dispatch({ type: "error_download", payload: message });
      }
    },
    [dispatch, ecmEnv, projectId, accountId, actionId],
  );

  return {
    downloadReport,
    state,
    isLoading: state.status === DownloadReportStatus.LOADING,
  };
}
