import {
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  InputAdornment,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import React from "react";

export default function SelectComponent({
  data,
  loading,
  inputLabel,
  label,
  showManageButtons,
  selectedItem,
  showTextInput,
  textValue,
  createButtonDisabled,
  removeButtonDisabled,
  additionalColumn,
  onSelect,
  onCreateClick,
  onRemoveClick,
  getSelectItemLabel,
}: InferProps<typeof SelectComponent.propTypes>) {
  return (
    <Grid
      container
      spacing={5}
      alignItems="center"
      className="selectComponent"
      marginBottom="5px"
    >
      <Grid item xs={2}>
        {label}
      </Grid>
      <Grid item xs={12} sm={3} className="MuiGrid-removeMargin">
        {!showTextInput ? (
          <Autocomplete
            disablePortal
            id="combo-box-select"
            options={data || []}
            loading={loading || false}
            fullWidth={true}
            disableClearable={true}
            value={selectedItem}
            renderInput={params => (
              <TextField
                {...params}
                label={inputLabel}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(_event, value) => {
              if (onSelect) {
                onSelect(value);
              } else {
                console.log("selected value=", value);
              }
            }}
            getOptionLabel={item =>
              getSelectItemLabel ? getSelectItemLabel(item) : item
            }
          />
        ) : (
          <TextField
            variant="outlined"
            value={textValue}
            inputProps={{ "aria-readonly": true }}
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
      {showManageButtons ? (
        <Grid item xs={12} sm={7}>
          <Button
            variant="outlined"
            disabled={createButtonDisabled || false}
            onClick={() => {
              if (onCreateClick) {
                onCreateClick();
              } else {
                console.log("create button clicked");
              }
            }}
          >
            Create
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="error"
            disabled={removeButtonDisabled || false}
            onClick={() => {
              if (onRemoveClick) {
                onRemoveClick();
              } else {
                console.log("remove button clicked");
              }
            }}
          >
            Remove
          </Button>
        </Grid>
      ) : null}
      {additionalColumn && <Grid item>{additionalColumn}</Grid>}
    </Grid>
  );
}

SelectComponent.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  showManageButtons: PropTypes.bool,
  selectedItem: PropTypes.any,
  onSelect: PropTypes.func,
  onCreateClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  showTextInput: PropTypes.bool,
  textValue: PropTypes.string,
  createButtonDisabled: PropTypes.bool,
  removeButtonDisabled: PropTypes.bool,
  getSelectItemLabel: PropTypes.func,
  additionalColumn: PropTypes.element,
};
