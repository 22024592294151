import { Button } from "@aws-amplify/ui-react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";

interface ComponentProps {
  logoutFunction: () => void;
}

export default function TopAppBar({ logoutFunction }: ComponentProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          ></IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Import Automation Tool
          </Typography>
          <Button color="inherit" onClick={logoutFunction}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
